import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as MenuSvg } from '../assets/icons/header_menu.svg';
import { ReactComponent as LogoSvg } from '../assets/icons/logo.svg';
import { ReactComponent as SearchBlackSvg } from '../assets/icons/search_black.svg';
import { ReactComponent as SearchSvg } from '../assets/icons/search.svg';
import { ReactComponent as UserSvg } from '../assets/icons/user.svg';

import Form from 'components/form/Form';
import FormItem from 'components/form/FormItem';
import TextInput from 'components/input/TextInput';
import AnimateLink from 'components/link/AnimateLink';
import useViewTransition from 'hooks/useViewTransition';
import colors from 'styles/theme/colors';
import media from 'styles/theme/media';
import size from 'styles/theme/size';
import AuthNav from './AuthNav';
import { useState } from 'react';
import { userState } from 'states/userState';
import { useRecoilValue } from 'recoil';

interface Search {
  search: string;
}

function header() {
  const user = useRecoilValue(userState);
  const form = useForm<Search>();
  const { pathname } = useLocation();
  const { onClickParamsLink: onClickSearch } = useViewTransition({ link: '/search' });
  const { onClickViewTransition: onClickUsers } = useViewTransition({ link: '/users' });
  const { onClickViewTransition: onClickSignIn } = useViewTransition({ link: '/auth/sign-in' });

  const [openMenu, setOpenMenu] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const onClickClose = () => {
    setOpenMenu(false);
    setOpenSearch(false);
  };

  const handleMenu = () => {
    setOpenSearch(false);
    setOpenMenu(!openMenu);
  };

  const handleSearch = () => {
    setOpenMenu(false);
    setOpenSearch(!openSearch);
  };

  const onClickUser = () => {
    onClickClose();

    if (user) onClickUsers();
    else onClickSignIn();
  };

  const handleSubmit = ({ search }: Search) => {
    if (!search) return;

    form.reset();
    setOpenSearch(false);
    onClickSearch(`search=${search}&state=teachers&sort=createdAt&start=0&perPage=10`);
  };

  return (
    <HeaderWrapper>
      {/* mobile */}
      <InnerMobile>
        <MenuSvg onClick={handleMenu} />
        {openMenu && (
          <MobileSearch
            onClick={(e) => {
              e.preventDefault();
              setOpenMenu(false);
            }}
          >
            <MobileMenu style={{ top: 0 }}>
              <LinkButton
                to={'/teachers?&sort=createdAt&start=0&perPage=20'}
                style={{ color: `${pathname === '/teachers' ? colors.keyColor.keyColor01 : colors.system.black}` }}
              >
                선생님 찾기
              </LinkButton>
              {/* <LinkButton
              to={'/classes?sort=createdAt&start=0&perPage=16'}
              style={{ color: `${pathname === '/classes' ? colors.keyColor.keyColor01 : colors.system.black}` }}
            >
              클래스
            </LinkButton> */}
              {user && (
                <LinkButton
                  to={'/mine?lectureStart=0&lecturePerPage=10&classesStart=0&classesPerPage=10'}
                  style={{ color: `${pathname === '/mine' ? colors.keyColor.keyColor01 : colors.system.black}` }}
                >
                  내수업
                </LinkButton>
              )}
            </MobileMenu>
          </MobileSearch>
        )}
        <LogoStyle to="/">
          <LogoSvg onClick={onClickClose} />
        </LogoStyle>
        <MobileNav>
          <SearchBlackSvg onClick={handleSearch} />
          {openSearch && (
            <MobileSearch
              onClick={(e) => {
                e.preventDefault();
                setOpenSearch(false);
              }}
            >
              <SearchInner onClick={(e) => e.stopPropagation()}>
                <Form form={form} onSubmit={handleSubmit}>
                  <FormItem name={'search'}>
                    <Input
                      // placeHolder="강사/클래스 찾기"
                      placeHolder="선생님 이름 검색"
                      rightIcon={
                        <button className="search">
                          <SearchSvg />
                        </button>
                      }
                    />
                  </FormItem>
                </Form>
              </SearchInner>
            </MobileSearch>
          )}
          <UserSvg onClick={onClickUser} />
        </MobileNav>
      </InnerMobile>

      {/* dektop */}
      <InnerDesktop>
        <LogoWrapper to="/">
          <LogoSvg />
        </LogoWrapper>
        <GnbNav>
          <LinkButton
            to={'/teachers?&sort=createdAt&start=0&perPage=20'}
            style={{ color: `${pathname === '/teachers' ? colors.keyColor.keyColor01 : colors.system.darkGray}` }}
          >
            선생님 찾기
          </LinkButton>
          {/* <LinkButton
            to={'/classes?sort=createdAt&start=0&perPage=16'}
            style={{ color: `${pathname === '/classes' ? colors.keyColor.keyColor01 : colors.system.darkGray}` }}
          >
            클래스
          </LinkButton> */}
          {user && (
            <LinkButton
              to={'/mine?lectureStart=0&lecturePerPage=10&classesStart=0&classesPerPage=10'}
              style={{ color: `${pathname === '/mine' ? colors.keyColor.keyColor01 : colors.system.darkGray}` }}
            >
              내수업
            </LinkButton>
          )}
        </GnbNav>
        <Form form={form} onSubmit={handleSubmit}>
          <FormItem name={'search'}>
            <Input
              // placeHolder="강사/클래스 찾기"
              placeHolder="선생님 이름 검색"
              rightIcon={
                <div className="search" onClick={() => form.handleSubmit}>
                  <SearchSvg />
                </div>
              }
            />
          </FormItem>
        </Form>
        <AuthNav />
      </InnerDesktop>
    </HeaderWrapper>
  );
}

export default header;

const HeaderWrapper = styled.header`
  background: ${colors.system.white};
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.08);
  padding: 0 ${size.sidePadding};
`;

const InnerMobile = styled.div`
  position: relative;
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: space-between;

  ${media.laptop} {
    display: none;
  }
`;
const LogoStyle = styled(AnimateLink)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const InnerDesktop = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  max-width: ${size.maxWidth};
  height: 72px;
  margin: 0 auto;

  & > nav > a,
  & > nav > span {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.system.darkGray};
    text-decoration: none;
    transition: 0.2s;

    &:hover {
      color: ${colors.keyColor.keyColor01};
    }
  }

  ${media.laptop} {
    display: flex;
  }
`;

const GnbNav = styled.nav`
  display: flex;
  gap: 60px;
`;

const MobileMenu = styled.nav`
  position: absolute;
  width: calc(100% + ${size.sidePadding} * 2);
  top: 72px;
  left: -${size.sidePadding};
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 28px;
  background-color: #fff;
  border-top: 1px solid ${colors.gray.gray50};
  z-index: 100;

  & > a {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;
    transition: 0.2s;
  }
`;

const MobileSearch = styled.div`
  position: absolute;
  width: calc(100% + ${size.sidePadding} * 2);
  top: 72px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
  left: -${size.sidePadding};
  z-index: 100;
`;

const SearchInner = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 28px;
  background-color: #fff;
  border-top: 1px solid ${colors.gray.gray50};
  z-index: 10;
`;

const MobileNav = styled.nav`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Input = styled(TextInput)`
  & {
    background-color: ${colors.gray.gray06};
  }

  & .search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    height: 100%;
    border: none;
    cursor: pointer;
  }
`;

const LogoWrapper = styled(AnimateLink)`
  cursor: pointer;
`;

const LinkButton = styled(AnimateLink)`
  cursor: pointer;
`;

const Logo = styled.img`
  width: 58px;
  height: 48px;
  object-fit: cover;
`;
